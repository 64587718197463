import React from "react";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

const NotFoundPage = ({ intl }) => (
  <div>
    <Helmet title={intl.formatMessage({ id: "globals.404.title" })} />
    <h1>
      <FormattedMessage id="globals.404.title" />
    </h1>
    <p>
      <FormattedMessage id="globals.404.intro" />
    </p>
  </div>
);

export default injectIntl(NotFoundPage);
